import { experimentEvent, } from '@/services/gtmManager';

export const state = () => ({
  // define currently running A/B experiments
  experiments: [
    {
      name: 'course-page-test',
      variants: ['A', 'B'],
    },
  ],
});

export const getters = {
  experiments: state => state.experiments,
};

export const actions = {
  // determines which variant to show to the user
  getVariant ({ commit, state }, experimentName) {
    const sessionKey = `${experimentName}-experiment-session`;

    // First, check if a variant exists in sessionStorage
    let variant = sessionStorage.getItem(sessionKey);
    if (!variant) {
      // If not found in sessionStorage, check cookies
      variant = this.$cookies.get(`${experimentName}-experiment`);

      if (!variant) {
        // If no cookie is found, create it and assign a random variant
        const experiment = state.experiments.find(
          experiment => experiment.name === experimentName
        );

        // Special rule for 'promo-cards' experiment
        variant = experiment.variants[
          experimentName === 'promo-cards' ? Math.floor(Math.random() >= 0.80) : Math.floor(Math.random() * experiment.variants.length)
        ];

        // Set variant in cookies for future visits
        this.$cookies.set(`${experimentName}-experiment`, variant, { secure: true });
      }

      // Store the variant in sessionStorage for the duration of the session
      sessionStorage.setItem(sessionKey, variant);

      // Send dataLayer event to GA
      experimentEvent(this.$gtm, experimentName, variant);
    }
    // Return the stored variant
    return variant;
  },
};
