import LocalStorageUtil from '../utils/LocalStorageUtil';
import { gtmSearch, } from '@/services/gtmManager';
// doing it this way to make giving default values easier. Otherwise we
// will have to do a bunch of if null checks to make sure the page type
// exists, and can be selected like an enum.
const PageType = {
  SESSIONS: 'SESSIONS',
  COURSES: 'COURSES',
  CORE100WEBINARS: 'CORE100WEBINARS',
  CORE100: 'CORE100',
};

const DEFAULT_SELECTED = {
  profession: [],
  state: [],
  format: [],
  topic: [],
  setting: [],
  credits: [],
  newCourses: [],
};
export const state = () => {
  return {
    searchText: '',
    pageView: 'COURSES',
    loadingCourses: true,
    courses: [],
    allCourses: [],
    loadingSessions: true,
    sessions: [],
    allSessions: [],
    selected: { ...DEFAULT_SELECTED },
    state: {},
    profession: {},
    newCourses: {
      id: false,
    },
  };
};

// TODO: remove unnecessary console.log's
export const getters = {
  PageType () {
    return PageType;
  },
  page (state) {
    return state.pageView;
  },
  selected (state) {
    // const selected = LocalStorageUtil.get('selected');
    // this allows and argument to be passed into the getter on the component side, and allows the component
    // not to need to know how the data is structured and can just get get data based other variables
    return type => state.selected[type];
  },
  chips (state) {
    const chips = [].concat(...Object.values(state.selected));
    console.log('CHIPS');
    console.log(chips);
    return chips.filter(chipName => !chipName.type.includes('newCourses'));
  },
  loading (state) {
    switch (state.pageView) {
      case PageType.COURSES:
        return state.loadingCourses;
      case PageType.SESSIONS:
        return state.loadingSessions;
      default:
        return false;
    }
  },
  searchText (state) {
    return state.searchText;
  },
  newCoursesState (state) {
    return state.selected.newCourses;
  },
  /* confusing to use the word "state" to mean both location and application state
   * state and profession getters below used to refer the global state/profession selector (state.state, state.profession);
   * currently everything is managed by what's selected and stored in Local Storage, so we're returning that
   */
  state (state) {
    return state.selected.state[0] || {};
  },
  profession (state) {
    return state.selected.profession[0] || {};
  },
  setting (state) {
    return state.selected.setting[0] || {};
  },
  allFormats (state) {
    return state.selected.format || [];
  },
  courses (state) {
    return state.courses;
  },
  sessions (state) {
    return state.sessions;
  },
  CORE100WEBINARS (state) {
    return state.sessions;
  },
  CORE100 (state) {
    return state.courses;
  },
};

export const actions = {
  initializeSearchFilters ({ commit }) {
    if (!LocalStorageUtil.get('selected')?.newCourses) {
      LocalStorageUtil.set('selected', DEFAULT_SELECTED);
    } else {
      commit('initializeSearchFilters');
    }
  },
  loadCookieData ({ dispatch }) {
    dispatch('setState', this.$cookies.get('state'));
    dispatch('setProfession', this.$cookies.get('profession'));
  },
  async resetFilters ({
    commit,
    dispatch,
  }) {
    // clear filters, then go get the courses without filters
    commit('clear');
    commit('clearLocalStorage');
    return await dispatch('applyFilters');
  },
  resetAndSet ({ commit, dispatch }, newFilter) {
    commit('clear');
    commit('setSelected', newFilter);
  },
  resetAndSetLocalStorage ({ commit, dispatch }, newFilter) {
    commit('clearLocalStorage');
    commit('setLocalStorage', newFilter);
  },
  setFilter ({ commit }, newFilters) {
    console.log('SET FILTER');
    console.log(newFilters);
    if (newFilters) {
      commit('setSelected', newFilters);
    }
  },
  filterNewCourses ({ commit, dispatch }, newCourses) {
    commit('setLocalStorage', newCourses);
    if (newCourses.id) {
      commit('setNewCourses');
      commit('setNewSessions');
    } else {
      commit('resetCourses');
      dispatch('applyFilters');
    }
  },
  removeFilter ({
    commit,
    dispatch,
  }, removedFilter) {
    commit('remove', removedFilter);
    dispatch('applyFilters');
  },
  setPage ({ commit, dispatch }, newPage) {
    commit('setPage', PageType[newPage] || PageType.COURSES);
    dispatch('applyFilters');
  },
  async applyFilters ({
    dispatch,
    state,
  }) {
    if (state.pageView === 'COURSES') {
      await dispatch('getCourses');
    } else if (state.pageView === 'SESSIONS') {
      await dispatch('getSessions');
    } else if (state.pageView === 'CORE100WEBINARS') {
      await dispatch('getCORE100WEBINARS');
    } else if (state.pageView === 'CORE100') {
      await dispatch('getCORE100');
    }
  },
  updateSearchText ({ commit }, newSearchText) {
    commit('setSearchText', newSearchText);
  },
  async getCourses ({
    state,
    commit,
    dispatch,
  }) {
    try {
      commit('setLoadingCourses', true);
      // sets selected on /courses search on refresh
      dispatch('initializeSearchFilters');
      const selected = prepSelectedData(state.searchText, state.selected);
      console.log('GET COURSE CALL');
      console.log(selected);
      if (state.searchText) {
        gtmSearch(this.$gtm, state.searchText);
      }
      const courses = await this.$axios.$post('/search/courses', selected);
      commit('setCourses', courses);
    } catch (e) {
      // TODO: make an error message? Not sure if there should be an error message here
    } finally {
      commit('setLoadingCourses', false);
    }
  },
  async getCORE100 ({
    state,
    commit,
  }) {
    try {
      commit('setLoadingSessions', true);
      const selected = prepSelectedCore100Data(state.searchText, state.selected);
      const courses = await this.$axios.$post('/search/core100', selected);
      commit('setCore100', courses);
    } catch (e) {
      // TODO: make an error message? Not sure if there should be an error message here
    } finally {
      commit('setLoadingCourses', false);
    }
  },
  async getSessions ({
    state,
    commit,
  }) {
    try {
      commit('setLoadingSessions', true);
      const selected = prepSelectedSessionData(state.searchText, state.selected);
      const sessions = await this.$axios.$post('/search/sessions', selected);
      commit('setSessions', sessions);
    } catch (e) {
      // TODO: make an error message? Not sure if there should be an error message here
    } finally {
      commit('setLoadingSessions', false);
    }
  },
  async getCORE100WEBINARS ({
    state,
    commit,
  }) {
    try {
      commit('setLoadingSessions', true);
      const selected = prepSelectedSessionData(state.searchText, state.selected);
      const sessions = await this.$axios.$post('/search/core100', selected);
      commit('setCore100Webinars', sessions);
    } catch (e) {
      // TODO: make an error message? Not sure if there should be an error message here
    } finally {
      commit('setLoadingSessions', false);
    }
  },
  // the following are more for setting the state and profession on the global search area
  setState ({ commit }, state) {
    if (!state || Object.keys(state).length === 0) {
      commit('clear');
    } else {
      commit('setState', {
        ...state,
        type: 'state',
      });
      commit('save');
    }
  },
  setProfession ({ commit }, profession) {
    if (!profession || Object.keys(profession).length === 0) {
      return;
    }
    commit('setProfession', profession);
    commit('save');
  },
};

// const cookieOptions = {};
export const mutations = {
  initializeSearchFilters (state) {
    console.log('INIT SEARCH FILERS');
    state.selected = LocalStorageUtil.get('selected');
    console.log(state.selected);
  },
  clear (state) {
    state.searchText = '';
    state.selected = { ...DEFAULT_SELECTED };
    LocalStorageUtil.set('selected', DEFAULT_SELECTED);
    state.state = [];
    state.profession = [];
  },
  clearLocalStorage (state) {
    LocalStorageUtil.set('selected', DEFAULT_SELECTED);
  },
  remove (state, filter) {
    if (!(filter?.type)) {
      return;
    }
    const selected = LocalStorageUtil.get('selected');
    selected[filter.type] = [];
    LocalStorageUtil.set('selected', selected);
    state.selected[filter.type] = (state.selected[filter.type] ?? []).filter(f => f.id !== filter.id);
    state.state = (state.selected[filter.type] ?? []).filter(f => f.id !== filter.id);
  },
  setLocalStorage (state, newFilter) {
    if (newFilter.type === 'subType') {
      const newArray = [];
      for (const format of newFilter.formats) {
        newArray.push(format);
      }
      const selected = LocalStorageUtil.get('selected');
      selected.format = newArray;
      state.selected = selected;
      LocalStorageUtil.set('selected', selected);
    } else {
      const selected = LocalStorageUtil.get('selected');
      selected[newFilter.type] = [newFilter];
      state.selected = selected;
      LocalStorageUtil.set('selected', selected);
    }
  },
  resetCourses (state) {
    console.log('RESET COURSES');
    state.newCourses.id = false;
    state.selected.newCourses.id = false;
    state.courses = state.allCourses;
  },
  setNewCourses (state) {
    const newCoursesList = [];
    state.allCourses = state.courses;
    state.courses.forEach((course) => {
      if (course.is_new) {
        newCoursesList.push(course);
      }
    });
    state.courses = newCoursesList;
  },
  setNewSessions (state) {
    const newSessionsList = [];
    state.allSessions = state.sessions;
    state.sessions.forEach((session) => {
      if (session.is_new) {
        newSessionsList.push(session);
      }
    });
    state.sessions = newSessionsList;
  },
  setCourses (state, courses) {
    console.log('SET COURSES');
    console.log(state.newCourses);
    console.log(courses);
    const newCoursesList = [];
    state.allCourses = courses;
    const selected = LocalStorageUtil.get('selected');
    console.log(selected.newCourses);
    console.log(selected.newCourses.newCourses);
    console.log(state.newCourses);
    if (selected.newCourses.newCourses) {
      console.log('should be sorting these courses..?');
      courses.forEach((course) => {
        if (course.is_new) {
          newCoursesList.push(course);
        }
      });
      console.log('should be sorted...');
      console.log(newCoursesList);
      state.courses = newCoursesList;
    } else {
      state.courses = courses;
    }
  },
  setSessions (state, sessions) {
    state.sessions = sessions;
  },
  setCore100Webinars (state, sessions) {
    state.sessions = sessions;
  },
  setCore100 (state, courses) {
    state.courses = courses;
  },
  setLoadingCourses (state, loading) {
    state.loadingCourses = loading;
  },
  setLoadingSessions (state, loading) {
    state.loadingSessions = loading;
  },
  setPage (state, page) {
    state.pageView = page;
  },
  setSelected (state, data) {
    const selectedArray = [];
    if (data?.selected && Array.isArray(data.selected)) {
      state.selected[data.type] = data.selected;
      data.selected.forEach(arr => selectedArray.push(arr));
      const selected = LocalStorageUtil.get('selected');
      selected[data.type] = selectedArray;
      LocalStorageUtil.set(`selected`, selected);
    } else {
      state.selected[data?.type] = [data];
      const selected = LocalStorageUtil.get('selected');
      if (typeof selected !== 'undefined') {
        selected[data?.type] = [data];
        state.selected[data?.type] = [data];
        LocalStorageUtil.set('selected', selected);
      }
    }
  },
  setSearchText (state, newSearchText) {
    state.searchText = newSearchText;
  },
  setState (state, newState) {
    // the first `state` is for the global drop down, and the second is for the filters
    state.state = Object.freeze(newState);
    state.selected.state = [Object.freeze(newState)];
    DEFAULT_SELECTED.state = [newState];
  },
  // async save (state) {
  //   await this.$cookies.setAll([
  //     {
  //       name: 'state',
  //       value: state.selected.state,
  //       opts: cookieOptions,
  //     },
  //     {
  //       name: 'profession',
  //       value: state.selected.profession,
  //       opts: cookieOptions,
  //     },
  //   ]);
  // },
  setProfession (state, profession) {
    state.profession = Object.freeze(profession);
    state.selected.profession = [Object.freeze(profession)];
    DEFAULT_SELECTED.profession = [profession];
  },
};

/**
 * Convert
 * {
 *   format: [
 *    {
 *      'id': 'live',
 *      'name': 'Live'
 *    },
 *    {
 *      'id': 'webinar',
 *      'name': 'Webinar'
 *    }
 *   ]
 * }
 * to
 * {
 *   format: ['live', 'webinar']
 * }
 * @param searchText {String}
 * @param selected {Object}
 */
function prepSelectedData (searchText, selected) {
  return (!selected || Object.keys(selected).length === 0)
    ? {}
    : Object.entries(selected).reduce((prev, [type, filters]) => {
      prev[type] = Array.isArray(filters) ? filters.map(({ id }) => id) : filters;
      return prev;
    }, { searchText });
}

/**
 * This is similar to the `prepSelectedData`, but we need to limit out only `live` and `webinar` formats.
 * We need to make sure that if there is `text` or `video` selected trim them out, and if the formats is
 * empty then make sure it has both `live` and `webinar`
 *
 * @param searchText {String}
 * @param selected {Object}
 */
function prepSelectedSessionData (searchText, selected) {
  const prepData = prepSelectedData(searchText, selected);
  // now re-apply the formats to the selected data
  prepData.format = getLiveWebinarFormatsOnly(prepData.format);
  return prepData;
}

function prepSelectedCore100Data (searchText, selected) {
  const prepData = prepSelectedData(searchText, selected);
  // now re-apply the formats to the selected data
  prepData.format = getVideoAndTextFormatsOnly(prepData.format);
  return prepData;
}

function getLiveWebinarFormatsOnly (formats) {
  // we need to filter out the non live formats
  if (formats && formats.length > 0) {
    // we only want live and webinar types
    formats = formats.filter((format) => {
      const f = format.toUpperCase();
      return f === 'LIVE' || f === 'WEBINAR' || f === 'LIVESTREAM';
    });
  }

  // if the data is empty we want to set it to live and webinar only
  if (formats.length === 0) {
    formats = [
      'LIVE', 'WEBINAR', 'LIVESTREAM',
    ];
  }

  return formats;
}
function getVideoAndTextFormatsOnly (formats) {
  // we need to filter out the non live formats
  if (formats && formats.length > 0) {
    // we only want live and webinar types
    formats = formats.filter((format) => {
      const f = format.toUpperCase();
      return f === 'VIDEO' || f === 'TEXT';
    });
  }

  // if the data is empty we want to set it to live and webinar only
  if (formats.length === 0) {
    formats = ['VIDEO', 'TEXT'];
  }

  return formats;
}
