//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, } from 'vuex';

export default {
  name: 'Default',
  head () {
    return {
      script: [
        {
          hid: 'trustpilot',
          src: '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          defer: true,
          // Changed after script load
        },
      ],
    };
  },
  async beforeMount () {
    const courseExperiment = await this.getVariant('course-page-test');
    sessionStorage.setItem('course-page-test-experiment', courseExperiment);
  },
  mounted () {
    this.initializeCart();
    this.initializeSearchFilters();
  },
  methods: {
    ...mapActions('cart', ['initializeCart']),
    ...mapActions('search', ['initializeSearchFilters']),
    ...mapActions(['setImpersonate']),
    ...mapActions({
      getVariant: 'experiments/getVariant',
    }),
    ...mapGetters('experiments'),
    async logout () {
      await this.$auth.logout();
      await this.$router.push('/login?logout=success');
      this.setImpersonate(false);
    },
  },
};
